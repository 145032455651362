import React from 'react'
import styled from 'styled-components'

import styles from '~/utils/styles'
import useI18n, { SHARE } from '~/utils/useI18N'

import LinkedIn from './LinkedIn'
import Reddit from './Reddit'
import Twitter from './Twitter'

const ButtonsGroup = styled.div`
  align-items: center;
  display: inline-flex;
  margin-top: 8px;

  a + a {
    margin-left: 2px;
  }

  ${styles.breakpoints.md} {
    bottom: 20px;
    left: 20px;
    flex-direction: column;
    margin-top: 0px;
    position: fixed;

    a + a {
      margin-left: 0px;
      margin-top: 2px;
    }
  }

  ${styles.breakpoints.lg} {
    bottom: 50px;
    left: 50px;
  }
`

const ShareText = styled.p`
  color: ${({ theme: { colors } }) => colors.gray};
  font-family: 'Montserrat', sans-serif;
  margin-right: 8px;

  ${styles.breakpoints.md} {
    display: none;
  }
`

const ShareButtons = ({ url, message }) => {
  return (
    <ButtonsGroup>
      <ShareText>{useI18n(SHARE)}</ShareText>
      <Twitter link={url} message={message} />
      <LinkedIn link={url} />
      <Reddit link={url} message={message} />
    </ButtonsGroup>
  )
}

export default ShareButtons
