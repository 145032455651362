import React from 'react'
import styled from 'styled-components'

import ArticleHero from '~/components/ArticleHero'
import NextPostCTA from '~/components/buttons/NextPostCTA'
import Container from '~/components/Container'
import Content from '~/components/Content'
import Markdown from '~/components/Markdown'
import ShareButtons from '~/components/social/ShareButtons'
import TranslatedOptions from '~/components/TranslatedOptions'
import useBlogMetadata from '~/staticQueries/useBlogMetadata'
import styles from '~/utils/styles'
import { POST_TRANSLATED_OPTIONS } from '~/utils/useI18N'

export default function Article({ availableLanguages, data, previousPost, nextPost }) {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useBlogMetadata()
  const { fields } = data
  const url = `${siteUrl}/${fields.lang}/${fields.slug}`

  return (
    <Container>
      <ArticleHero {...data.fields} {...data.frontmatter} />
      <TranslatedOptions
        availableLanguages={availableLanguages}
        descriptionKey={POST_TRANSLATED_OPTIONS}
      />
      <Content>
        <Markdown dangerouslySetInnerHTML={{ __html: data.html }} />
        <ShareButtons message={data.frontmatter.title} url={url} />
        {(previousPost || nextPost) && (
          <ArticleNavigation>
            {previousPost ? <StyledButton variant="left" {...previousPost} /> : <span />}
            {nextPost ? <StyledButton {...nextPost} /> : <span />}
          </ArticleNavigation>
        )}
      </Content>
    </Container>
  )
}

const ArticleNavigation = styled.nav`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 3rem 0;

  ${styles.breakpoints.md} {
    flex-direction: row;
    justify-content: space-between;
    padding: 8rem 0;

    span,
    a {
      flex: 1;
    }
  }
`

const StyledButton = styled(NextPostCTA)`
  visibility: visible;
  display: block;

  & + a {
    margin-top: 3rem;
  }

  &:last-child {
    ${styles.breakpoints.md} {
      margin-top: 0;
      text-align: right;
    }
  }
`
