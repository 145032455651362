import React from 'react'
import styled from 'styled-components'

import Link from '~/components/Link'

const NextPostCTA = ({ variant = 'right', link, title, className }) => {
  const text = variant === 'left' ? `← ${title}` : `${title} →`
  return (
    <StyledLink to={link} variant={variant} className={className}>
      {text}
    </StyledLink>
  )
}
export default NextPostCTA

const StyledLink = styled(Link)`
  display: block;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  text-align: left;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: ${({ theme: { colors } }) => colors.darkBlue};
  }
`
