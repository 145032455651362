import { graphql } from 'gatsby'
import React from 'react'

import Article from '~/components/Article'
import Layout from '~/components/layouts'
import Seo from '~/components/Seo'

const getPostAbstract = (node) => {
  if (node) {
    return {
      ...node.fields,
      ...node.frontmatter,
      link: `/${node.fields.slug}`,
    }
  }
  return null
}

const Post = ({
  data: { markdownRemark: data },
  pageContext: { availableLanguages, lang, link, previousPost, nextPost },
}) => {
  return (
    <>
      <Seo blogMetadata={data.frontmatter} lang={lang} link={link} />
      <Layout lang={lang}>
        <Article
          availableLanguages={availableLanguages}
          data={data}
          nextPost={getPostAbstract(nextPost)}
          previousPost={getPostAbstract(previousPost)}
        />
      </Layout>
    </>
  )
}

export default Post

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!, $dateFormat: String!, $lang: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        lang
        slug
        readingTime {
          minutes
        }
      }
      frontmatter {
        title
        author
        date(formatString: $dateFormat, locale: $lang)
        description
        heroImage {
          childImageSharp {
            fixed(width: 1600, height: 900) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      html
    }
  }
`
