import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

import AuthorLink from '~/components/AuthorLink'
import Content from '~/components/Content'
import DotList from '~/components/DotList'
import Markdown from '~/components/Markdown'
import { upperFirst } from '~/utils/strings'
import styles from '~/utils/styles'
import useI18n, { READING_TIME } from '~/utils/useI18N'

const ArticleHero = ({ author, date, heroImage, readingTime, slug, title }) => {
  const readingTimeText = useI18n(READING_TIME, { MIN: Math.ceil(readingTime.minutes) })

  if (!heroImage?.childImageSharp?.fixed) {
    return (
      <Content>
        <Markdown dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }} />
        <Eyebrow gray>
          <DotList
            list={[<AuthorLink author={author} key="author" />, upperFirst(date), readingTimeText]}
          />
        </Eyebrow>
      </Content>
    )
  }

  return (
    <HeroContainer>
      <HeroImage
        fixed={heroImage.childImageSharp.fixed}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={slug}
      />
      <HeroContent>
        <Headline>{title}</Headline>
        <Eyebrow>
          <DotList list={[<AuthorLink author={author} key="author" white />, date]} />
        </Eyebrow>
        <Eyebrow>{readingTimeText}</Eyebrow>
      </HeroContent>
    </HeroContainer>
  )
}

export default ArticleHero

const HeroContainer = styled.header`
  text-align: center;
  margin-bottom: 1rem;
  color: ${({ theme: { colors } }) => colors.white};
  position: relative;
  margin: 0 -2rem 2rem;

  ${styles.breakpoints.md} {
    margin: 0 -4rem 2rem;
  }
`

const HeroImage = styled(GatsbyImage)`
  width: 100% !important;
  height: auto !important;
  padding-bottom: 36.25% !important;
  min-height: 30rem;
  position: relative;
  ${styles.breakpoints.md} {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding-bottom: 46.25% !important;
  }

  &:after {
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(74, 74, 74, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const HeroContent = styled.div`
  width: 100%;
  position: absolute;
  top: 66.66%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2rem;

  ${styles.breakpoints.md} {
    padding: 0 4rem;
  }
`

const Headline = styled.h1`
  font-size: 3.2rem;
  text-align: center;
  font-weight: 900;
  letter-spacing: 0.1rem;
  margin: 0;
  max-width: 80vw;
  margin: 0 auto;
  padding-bottom: 2rem;

  ${styles.breakpoints.md} {
    padding-bottom: 3rem;
  }
`

const Eyebrow = styled(({ gray, ...props }) => <span {...props} />)`
  display: block;
  padding-bottom: 0.5rem;
  font-family: 'Source Code Pro', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  color: ${({ gray, theme: { colors } }) => (gray ? colors.gray : 'inherit')};

  ${styles.breakpoints.md} {
    letter-spacing: 0.2rem;
    font-size: 1.4rem;
  }
`
