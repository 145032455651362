import React from 'react'
import styled from 'styled-components'

const usedLabel = (name) => `Share on ${name}`

const Link = ({ children, href, name, target = '_blank', ...restProps }) => {
  const label = usedLabel(name)
  return (
    <a
      href={href}
      target={target}
      rel="noreferrer noopener"
      aria-label={label}
      title={label}
      {...restProps}
    >
      {children}
    </a>
  )
}

export const StyledLink = styled(({ bgColor, bgColorFocus, ...props }) => <Link {...props} />)`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  transition: 25ms ease-out;
  width: 30px;
  background-color: ${({ bgColor }) => bgColor};

  &:focus,
  &:hover {
    background-color: ${({ bgColorFocus }) => bgColorFocus};
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  fill: #fff;
  stroke: none;

  svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
`
