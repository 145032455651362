import styled from 'styled-components'

const Content = styled.section`
  ${({ theme: { spacing } }) => `
    padding-bottom: ${spacing.sm};
    padding-top: ${spacing.sm};
  `}
`

export default Content
